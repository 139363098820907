
import Vue from 'vue'
import firebase from 'firebase/compat/app'
interface Venue {
  name: string;
  city: string;
  location?: {
    latitude: null;
    longitude: null;
  };
}

export default Vue.extend({
  name: 'Venues',
  data () {
    return {
      valid: false,
      loading: false,
      dialog: {
        create: false,
        location: false
      },
      geoInput: '',
      geoInputValid: false,
      createData: {
        name: '',
        nameRules: [
          (v: any) => !!v || this.$t('name-form-rule')
        ],
        city: '',
        cityRules: [
          (v: any) => !!v || this.$t('city-form-rule')
        ],
        disabled: false
      },
      VenueData: {
        name: '',
        city: '',
        location: {
          latitude: 0,
          longitude: 0
        }
      },
      dialogData: {},
      items: {}
    }
  },
  mounted () {
    this.$store.dispatch('venues/fetchAndAdd')
  },
  methods: {
    setupVenueInputs () {
      if (this.geoInput && this.geoInput.includes(',')) {
        const geo = this.geoInput.split(',')
        if (!isNaN(parseFloat(geo[0])) && !isNaN(parseFloat(geo[1]))) {
          this.VenueData.location.latitude = parseFloat(geo[0])
          this.VenueData.location.longitude = parseFloat(geo[1])
          this.geoInputValid = true
        } else {
          alert(this.$t('geo-location-alert'))
          this.geoInputValid = false
        }
      } else {
        alert(this.$t('geo-location-not-right'))
      }
    },
    createDialog () {
      this.dialog.create = true
    },
    openDialog (document: Venue) {
      this.dialogData = document
      this.dialog.location = true
    },
    validate () {
      const form: any = this.$refs.venueForm
      if (form.validate()) {
        this.loading = true
        const data = { name: this.createData.name, city: this.createData.city, location: {} }
        if (this.VenueData.location.latitude !== 0 && this.geoInputValid) {
          const geoLocation = new firebase.firestore.GeoPoint(this.VenueData.location.latitude, this.VenueData.location.longitude)
          data.location = geoLocation
        }
        this.$store.dispatch('venues/set', data).then(() => {
          this.dialog.create = false
          this.createData.name = ''
          this.createData.city = ''
          this.geoInput = ''
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
    },
    unlink (id: string) {
      this.$store.dispatch('venues/delete', id)
    }
  }
})
